import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    keepAlive: ['orderMxjList','goodsList','huxingDetail']
  },
  mutations: {
    SET_KEEP_ALIVE: (state, keepAlive) => {
      // console.log(keepAlive);
      state.keepAlive = keepAlive
    }
  },
  getters: {
    keepAlive: state => state.keepAlive
  },
  // state: {
  // },
  // mutations: {
  // },
  actions: {
  },
  modules: {
  }
})
