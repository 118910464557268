import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vconsole from 'vconsole'
// new Vconsole()

import VueResource from 'vue-resource'
Vue.use(VueResource)

// import mt_app_methods from 'mt-app-methods'
// mt_app_methods.sendPhone()
// console.log(mt_app_methods)



import { Overlay ,Loading ,Toast ,Field , Form ,Button,CellGroup,Grid,GridItem,ActionSheet, Uploader } from 'vant'

// import { RadioGroup, Radio,PullRefresh,Tabbar, TabbarItem, NavBar,Search,Uploader,Swipe, SwipeItem,Picker,Popup,Progress,Form,Field,Calendar,Area,Overlay,Loading,Checkbox,CheckboxGroup,Cell,CellGroup,DatetimePicker,Tab,Tabs,ImagePreview,Dialog,Toast} from 'vant'
// Vue.use(RadioGroup)
// Vue.use(Radio)
// Vue.use(PullRefresh)
// Vue.use(Tabbar)
// Vue.use(TabbarItem)
// Vue.use(NavBar)
// Vue.use(Search)
// Vue.use(Uploader)
// Vue.use(Swipe);
// Vue.use(SwipeItem);
// Vue.use(Picker);
// Vue.use(Popup);
// Vue.use(Progress);
Vue.use(Form);
Vue.use(Field);
// Vue.use(Calendar);
// Vue.use(Area);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Button)
// Vue.use(Checkbox);
// Vue.use(CheckboxGroup);
// Vue.use(Cell);
Vue.use(CellGroup);
// Vue.use(DatetimePicker);
// Vue.use(Tab);
// Vue.use(Tabs);
// Vue.use(ImagePreview);
// Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(ActionSheet);
Vue.use(Uploader)
// Vue.prototype.$Dialog = Dialog
Vue.prototype.$Toast = Toast
import * as util from './utils/util'
Vue.prototype.$util = util


// import * as MovitUtils from 'movit-utils'
// Vue.prototype.$MovitUtils = MovitUtils
//
// import BScroll from 'better-scroll'
//
// import BScrollCore from '@better-scroll/core'
// import NestedScroll from '@better-scroll/nested-scroll'
// import PullDown from '@better-scroll/pull-down'
// import Pullup from '@better-scroll/pull-up'
// BScrollCore.use(NestedScroll)
// BScrollCore.use(PullDown)
// BScrollCore.use(Pullup)



// import VConsole from 'vconsole'
// new VConsole()

// Vue.prototype.$BScroll = BScroll
//
// Vue.prototype.$BScrollCore = BScrollCore

Vue.config.productionTip = false

//将数据是json字符串的转为对象，并取值
// Vue.filter('parseDataFilter', function (value,item) {
//   if (!value) return ''
//   value = value.toString()
//   return JSON.parse(value)[item]
// })


new Vue({
  router,
  store,
  data: {
     root_show_loading: false
 	},
  render: h => h(App)
}).$mount('#app')
