
// import Vue from 'vue'

// import VueResource from 'vue-resource'
// import VueRouter from 'vue-router'

// console.log(VueRouter)

import axios from 'axios';
import { Toast } from 'vant';

import router from '../router'

let OSS = require('ali-oss')
let client = new OSS({
    region: 'oss-cn-shanghai',
    //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，部署在服务端使用RAM子账号或STS，部署在客户端使用STS。
    accessKeyId: 'LTAI4FqSpJTvmH1LajPZp68j',
    accessKeySecret: 'A0dSWL2AKjE3extYSmXNW6GJpENBwM',
    bucket: 'spjgzsapi'
})
const ossPut = async (ObjName, fileUrl) => {
    try {
        let date = new Date()
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let dir = year+ '/' + month + '/' + day
        let result = await client.put(`${dir}/${ObjName}`, fileUrl)
        // result.targetUrl = targetUrl + '/' + result.name
        // result.name = ObjName
        return result
    } catch (e) {
        console.log(e)
    }
}



// dev
// const base_api = 'http://218.70.38.46:5010/mcrm-rest/api';
// const base_api = 'https://cms-uat.yango.com.cn/mcrmapi';

// if (process.env.NODE_ENV === 'dev') {
//    base_api = 'https://cms-uat.yango.com.cn/mcrmapi';
// }

// const base_api = 'https://cms-uat.yango.com.cn/mcrmapi'; //uat

const base_api = '/api'; //dev

// const base_api = '/fbapi';


// 处理金额
const numFormat = (num) => {
	if (!num){
		return num;
	}
	num=num.toString().split(".");  // 分隔小数点
	var arr=num[0].split("").reverse();  // 转换成字符数组并且倒序排列
	var res=[];
	for(var i=0,len=arr.length;i<len;i++){
		if(i%3===0&&i!==0){
			res.push(",");   // 添加分隔符
		}
		res.push(arr[i]);
	}
	res.reverse(); // 再次倒序成为正确的顺序
	if(num[1]){  // 如果有小数的话添加小数部分
		res=res.join("").concat("."+num[1]);
	}else{
		res=res.join("");
	}
	return res;
}




// ios系统new Date(xxxx-xx-xx)的时候会报NaN-NaN-NaN NaN:NaN异常
const formatTime = (date,is_time) => {
	 const year = date.getFullYear()
	 const month = date.getMonth() + 1
	 const day = date.getDate()
	 const hour = date.getHours()
	 const minute = date.getMinutes()
	 const second = date.getSeconds()

	 const formatNumber = n => {
			n = n.toString()
			return n[1] ? n : '0' + n
	 }
	 let str = '';
	 if (!!is_time) {
			str = [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':');
	 }else{
			str = [year, month, day].map(formatNumber).join('-');
	 }
	 return str;
	 // + ' ' + [hour, minute, second].map(formatNumber).join(':')
};

// 格式化 日期
const format_date = (date) => {
	let str = '';
	if (!!date) {
		str = date.replace(/-/g, '-').replace('T', ' ').replace('.000+0000', '');
	}else{
		str = '';
	}
	return str;
};

// ios系统new Date(xxxx-xx-xx)的时候会报NaN-NaN-NaN NaN:NaN异常
const date_to_stamp = (date) => {
	let _arr = [];
	let str = null;
	if (!!date && date.indexOf('-')>=0) {
		_arr = date.split('-');
		str = new Date(_arr[0],Number(_arr[1])-1,_arr[2]);
	}else {
		str = new Date();
	}
	return str;
};

const params_to_string = data =>{
	 let str = '';
	 for(let i in data){
			str = str + '&' + i + '=' + data[i];
	 }
	 str = str.replace(/&/,'?');
	 return str;
};


//手机号码

//验证规则：11位数字，以1开头。
//电话号码

//验证规则：区号+号码，区号以0开头，3位或4位
// 号码由7位或8位数字组成
// 区号与号码之间可以无连接符，也可以“-”连接

const check_phone = str =>{
	 var flag = true;
	 if(!!str) {
			if(str.substring(0, 1) == '1') {
				 var re = /^1\d{10}$/;
				 if(re.test(str)) {
						flag = true;
				 } else {
						flag = false;
				 }
			} else {
				 // var re2 = /^0\d{2,3}-?\d{7,18}$/;
				 var re2 = /^[0-9]*$/;
				 if(re2.test(str)) {
						flag = true;
				 } else {
						flag = false;
				 }
			}
	 };
	 return flag;
};
// console.log(check_phone('0222'));

// 验证 身份证
const check_card = card =>{
	 let len = card.length;
	 let verifyBirthday = function(year,month,day,birthday){
			var now = new Date();
			var now_year = now.getFullYear();
			//年月日是否合理
			if(birthday.getFullYear() == year && (birthday.getMonth() + 1) == month && birthday.getDate() == day){
				 //判断年份的范围（0岁到100岁之间)
				 var time = now_year - year;
				 if(time >= 0 && time <= 100){
						return true;
				 }
				 return false;
			}
			return false;
	 };
	 //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
	 if(len == '15'){
		 var re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
		 var arr_data = card.match(re_fifteen);
		 var year = arr_data[2];
		 var month = arr_data[3];
		 var day = arr_data[4];
		 var birthday = new Date('19'+year+'/'+month+'/'+day);
		 return verifyBirthday('19'+year,month,day,birthday);
	 }
	 //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
	 if(len == '18'){
		 var re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X|x)$/;
		 var arr_data = card.match(re_eighteen);
		 var year = arr_data[2];
		 var month = arr_data[3];
		 var day = arr_data[4];
		 var birthday = new Date(year+'/'+month+'/'+day);
		 return verifyBirthday(year,month,day,birthday);
	 }
	 return false;
};

const convertToChinaNum = num =>{
    var arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
    var arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千','万', '十', '百', '千','亿');//可继续追加更高位转换值
    if(!num || isNaN(num)){
        return "零";
    }
    var english = num.toString().split("")
    var result = "";
    for (var i = 0; i < english.length; i++) {
        var des_i = english.length - 1 - i;//倒序排列设值
        result = arr2[i] + result;
        var arr1_index = english[des_i];
        result = arr1[arr1_index] + result;
    }
    //将【零千、零百】换成【零】 【十零】换成【十】
    result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
    //合并中间多个零为一个零
    result = result.replace(/零+/g, '零');
    //将【零亿】换成【亿】【零万】换成【万】
    result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
    //将【亿万】换成【亿】
    result = result.replace(/亿万/g, '亿');
    //移除末尾的零
    result = result.replace(/零+$/, '')
    //将【零一十】换成【零十】
    //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
    //将【一十】换成【十】
    result = result.replace(/^一十/g, '十');
    return result;
};


axios.defaults.timeout = 1000000

axios.interceptors.request.use(config => {
	 // Vue.prototype.$show_loading = true;

	 // Vue.prototype.$forceUpdate();
	 // console.log(Vue.prototype.$show_loading);
	 // 在发送请求之前做些什么，比如设置token
	 if(!!localStorage.token) {
         config.headers.token = localStorage.token
     }
	 // console.log(config);
	 // config.url = '/mcrmapi/file/common/upload';
	 // if (config.url == '/mcrmapi/file/common/upload' || config.url.indexOf('/mcrmapi/file/common/posterUpload')>=0) {
		// 	config.headers['Content-Type'] = 'multipart/form-data';
		// 	config.headers['Accept'] = 'application/json';
	 // }

	 return config;
}, error => {
	 return Promise.reject(error);
})

axios.interceptors.response.use(res => {
	// console.log(res.data);
	if (res.data.code === 500){
        Toast(res.data.message)
        return res.data
	}else if (res.data.code === 403){
        Toast(res.data.message + ",请重新登录")
        router.replace({
            path: '/login'
        })
        return res.data
    }else {
		return res.data
	}
}, error => {
	 // console.log(error.response);
	 // if(error.response.status === 403){
		// 	Toast('登录过期');
	 // }else{
		// 	if (!!error.response.data.message) {
		// 		 Toast(error.response.data.message);
		// 	}else{
		// 		 Toast('网络错误');
		// 	}
	 // }
	 return error.response.data;
	 // return Promise.reject(error)
})

const ajax = (_url,_method,_params,_is_loading) => {

	 // console.log(Vue.prototype);
			// Vue.prototype.$show_loading = true;
			// this.$root.root_msg

	 // Vue.prototype.$forceUpdate();

//          Toast.loading({
//   message: '加载中...',
//   forbidClick: true
// });





	 // if (!_is_loading) {
	 //    // _is_loading 存在值 就不加载 loading
//       Toast.loading({
//   message: '加载中...',
//   forbidClick: true
// });
	 // }




	 return new Promise(function(resolve, reject) {
			if (_method == 'POST' || _method == 'post') {
				 axios.post(base_api + _url, _params).then(res => {
						// console.log(res);
						resolve(res);
				 })
			}else{
				 axios.get(base_api + _url, {params: _params}).then(res => {
						// console.log(res);
						resolve(res);
				 })
			}
	 })
};

export {ajax,params_to_string,formatTime,check_phone,check_card,date_to_stamp,convertToChinaNum,format_date,base_api,numFormat,ossPut}
// export default {
//    formatTime: formatTime,
//    ajax: ajax,
//    params_to_string: params_to_string,
//    go_url: go_url,
//    toast: toast,
//    base_api: base_api
// }
