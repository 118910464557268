import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'home',
		component: () =>
			import('@/views/home.vue'),
		meta: {
			index: 1
		}
	},
    {
        path: '/ytDlp',
        name: 'ytDlp',
        component: () =>
            import('@/views/ytDlp.vue'),
        meta: {
            index: 1
        }
    },
    {
        path: '/ytDlpList',
        name: 'ytDlpList',
        component: () =>
            import('@/views/ytDlpList.vue'),
        meta: {
            index: 1
        }
    },
    {
        path: '/print',
        name: 'print',
        component: () =>
            import('@/views/print.vue'),
        meta: {
            index: 1
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('@/views/login.vue'),
        meta: {
            index: 1
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
            import('@/views/dashboard.vue'),
        meta: {
            index: 1
        }
    },
    {
        path: '/commodityPrice',
        name: 'commodityPrice',
        component: () =>
            import('@/views/commodityPrice.vue'),
        meta: {
            index: 1
        }
    },
    {
        path: '/commodityManagement',
        name: 'commodityManagement',
        component: () =>
            import('@/views/commodityManagement.vue'),
        meta: {
            index: 1
        }
    },
    {
        path: '/orderList',
        name: 'orderList',
        component: () =>
            import('@/views/orderList.vue'),
        meta: {
            index: 1
        }
    },
    {
        path: '/three/:id?',
        name: 'three',
        component: () =>
            import('@/views/three.vue'),
        meta: {
            index: 1
        }
    },
    {
        path: '/threeManage',
        name: 'threeManage',
        component: () =>
            import('@/views/threeManage.vue'),
        meta: {
            index: 1
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
export default router
