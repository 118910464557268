<template>
	<div id="app" class="container">
		<!--     <div id="nav">
			<router-link to="/">Home</router-link> |
			<router-link to="/about">About</router-link>
		</div> -->
		<van-overlay :show="$root.root_show_loading">
			<van-loading class="van-overlay-loading" />
		</van-overlay>

        <router-view></router-view>


<!--		<transition :name="transitionName">-->
<!--			<keep-alive :include="keepAlive">-->

<!--				<router-view :key="$route.fullPath"></router-view>-->

<!--			</keep-alive>-->
<!--		</transition>-->


		<!--		<transition :name="transitionName">-->
		<!--			<keep-alive >-->
		<!--				<router-view v-if="!!$route.meta.keepAlive"></router-view>-->
		<!--			</keep-alive>-->
		<!--		</transition>-->
		<!--		<transition :name="transitionName">-->
		<!--			<router-view v-if="!$route.meta.keepAlive"></router-view>-->
		<!--		</transition>-->
	</div>
</template>
<script>
	export default {
		name: 'App',
		data() {
			return {
				deviceType: localStorage.deviceType,
				transitionName: '',
				// show_loading: false
			}
		},
		watch: {
			$route(to, from) {
				// console.log(to);
				// console.log(from);
				// if(to.meta.index == 0) {
				// 	this.transitionName = '';
				// } else if(to.meta.index > from.meta.index) {
				// 	this.transitionName = 'slide-left';
				// } else if(to.meta.index == from.meta.index) {
				// 	this.transitionName = ''
				// } else {
				// 	this.transitionName = 'slide-right';
				// }
                //
				// if(to.name == 'customerDetail' && from.name == 'orderDetail') {
				// 	this.transitionName = '';
				// } else if(to.name == 'orderDetail' && from.name == 'customerDetail') {
				// 	this.transitionName = '';
				// } else {
                //
				// }
			}
		},
		computed: {
			keepAlive() {
				// console.log(this.$store.getters.keepAlive);
				return this.$store.getters.keepAlive
			}
		},
		created() {
			// this.show_loading = this.$root.root_msg;
			// console.log(this.$root.root_msg);
		},
		beforeCreate() {
			var deviceWidth = document.documentElement.clientWidth;
			if(deviceWidth > 750) {
				deviceWidth = 7.5 * 50
			}
			document.documentElement.style.fontSize = deviceWidth / 7.5 + 'px'
		},
		methods: {
			go_back() {
				// console.log(localStorage.touch_back);
				// if (localStorage.touch_back != '0' && this.$route.meta.index != 1){
				// 	this.$router.go(-1);
				// }
			}
		}
	}
</script>
<style lang="less">
	* {
		-webkit-touch-callout: none;
		/*系统默认菜单被禁用*/
		-webkit-user-select: none;
		/*webkit浏览器*/
		-khtml-user-select: none;
		/*早期浏览器*/
		-moz-user-select: none;
		/*火狐*/
		-ms-user-select: none;
		/*IE10*/
		user-select: none;
		-webkit-overflow-scrolling: touch;
	}
	input, textarea {
		-webkit-user-select: auto;
		user-select: auto;
		appearance: none;
		-webkit-appearance: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	html, body {
		width: 100%;
		height: 100%;
        padding: 0;
        margin: 0;
	}
    .container {
        width: 100%;
        height: 100%;
        font-size: 0.28rem;
        color: #333;
        overflow-x: hidden;
        overflow-y: auto;
    }
</style>